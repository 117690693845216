import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {ApiService} from "./services/api.service";
import {environment} from "../environments/environment";
import {AlertController, LoadingController, Platform} from "@ionic/angular";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {App} from "@capacitor/app";
import {StatusBar} from "@capacitor/status-bar";
import {SplashScreen} from "@capacitor/splash-screen";
import {Device} from "@capacitor/device";
import {
    AppTrackingTransparency,
    AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import {SeoService} from "./services/seo.service";
import {IResponse} from "./interfaces/IResponse";
import {DOCUMENT} from "@angular/common";
// import {SwUpdate} from "@angular/service-worker";

import 'capacitor-plugin-app-tracking-transparency';
import {ChatService} from "./services/chat.service";
import {MessagingService} from "./services/messaging.service";// only if you want web support

// Swiper
import {register} from "swiper/element/bundle";
register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    public loader;

    constructor(
        private platform: Platform,
        private zone: NgZone,
        private api: ApiService,
        private router: Router,
        private seo: SeoService,
        private activeRoute: ActivatedRoute,
        public alertController: AlertController,
        public loadingController: LoadingController,
        // private swUpdate: SwUpdate
        private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private chatService: ChatService,
        private messagingService: MessagingService
    ) {
        this.initializeApp();
    }

    async ngOnInit() {
        this.api.load("config");
        this.api.load("makes");
        this.chatService.reloadChats();

        try {
            const info = await Device.getInfo();
            this.document?.body?.classList?.add(info.platform);

            if (["android", "ios"].includes(info.platform)) {
                window.screen?.orientation?.lock('portrait').catch((reason) => {
                });
                await StatusBar.show();
                if (info.platform === "android") {
                    // Display content under transparent status bar (Android only)
                    // await this.setSafeArea();
                } else if (info.platform === "ios") {
                    await this.requestPermission();
                }
            }
        } catch (e) {
        }


        this.seo.set();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.seo.set();
            }
        });

        this.activeRoute.queryParams.subscribe(async params => {
            if (params.token && params.PayerID) {
                await this.handlePayPalPayment(params.token, params.PayerID);
            }
        });
    }

    public async handlePayPalPayment(token: string, payerID: string) {
        this.router.navigate([], {
            relativeTo: this.activeRoute,
            queryParams: {},
            queryParamsHandling: ''
        });

        this.loader = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: 'Please wait...'
        });
        await this.loader.present();

        this.api.handlePayPalPayment({
            token,
            payerID
        }).subscribe(async (res: IResponse) => {
            if (res.success && res.data) {
                await this.loader.dismiss();

                const alert = await this.alertController.create({
                    cssClass: 'connection-alert',
                    header: "Επιτυχία",
                    message: "Η πληρωμή σας πραγματοποιήθηκε με επιτυχία",
                    buttons: [{
                        text: 'OK',
                        role: 'cancel',
                        cssClass: 'secondary'
                    }]
                });
                await alert.present();
            } else {
                await this.loader.dismiss();
            }
        });
    }

    public async requestPermission(): Promise<AppTrackingStatusResponse> {
        return await AppTrackingTransparency.requestPermission();
    }

    public async setSafeArea() {
        await StatusBar.setOverlaysWebView({
            overlay: false
        });
        await this.delay(10);
        const initialHeight = this.document.documentElement.clientHeight;
        await StatusBar.setOverlaysWebView({
            overlay: true
        });
        await this.delay(10);
        const currentHeight = this.document.documentElement.clientHeight;
        const safeArea = currentHeight - initialHeight;
        this.document.documentElement.style.setProperty('--safe-area-top', `${safeArea}px`);
        // await StatusBar.setStyle({
        //     style: Style.Light
        // });
    }

    public async delay(ms: number = 0) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public initializeApp() {
        this.platform.ready().then(async () => {
            await SplashScreen.hide();
            this.messagingService.initializePushNotifications();
        });
        App.addListener('appUrlOpen', (data: any) => {
            this.zone.run(() => {
                const path = data.url.replace(environment.liveUrl, "");
                if (path) {
                    this.router.navigateByUrl(path);
                }
            });
        });
    }
}
