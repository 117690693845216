<div class="text-center">
    <img class="logo mx-3" src="/assets/logo/horizontal-blue.svg" alt="GarageBit"/>
    <div class="alert alert-info p-2 mt-3 mb-1">
        <div translate>You only need to Login to continue.</div>
        <b translate>It's so simple</b> 🚀
    </div>
</div>
<app-login-form
        [isModal]="true"
        (loggedIn)="loggedIn()"
></app-login-form>
