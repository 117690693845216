import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {IResponse} from "../interfaces/IResponse";
import {SocialProviders} from "../interfaces/general";
import {Observable, of} from "rxjs";
import {FirebaseAuthentication} from '@robingenz/capacitor-firebase-authentication';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import firebase from 'firebase/compat/app';
import {take} from "rxjs/operators";
import {ModalController, Platform} from "@ionic/angular";
import {IAuthData} from "../interfaces/IAuth";
import {Router} from "@angular/router";
import {IUser} from "../interfaces/IUser";
import {cloneDeep} from "lodash";
import {LoginModal} from "../components/modals/login/login-modal";
import {StorageService} from "./storage.service";

@Injectable({
    providedIn: "root"
})
export class AuthService {

    private loginUrl: string = environment.api + "/auth/login";
    private registerUrl: string = environment.api + "/auth/register";
    private resetPasswordUrl: string = environment.api + "/auth/reset-password";
    private newPasswordUrl: string = environment.api + "/auth/new-password";
    private logoutUrl: string = environment.api + "/auth/logout";
    private connectUrl: string = environment.api + "/auth/connect-partner";

    constructor(
        private http: HttpClient,
        private platform: Platform,
        private router: Router,
        private modalController: ModalController,
        public storage: StorageService,
        public auth: AngularFireAuth,
    ) {
    }

    public get isLoggedIn(): Observable<boolean> {
        return of(!!this.storage.get("user")?.id);
    }

    public async loginModal() {
        return this.modalController.create({
            component: LoginModal,
            cssClass: "login-modal",
            keyboardClose: true,
            showBackdrop: true,
            animated: true
        });
    }

    public async socialLogin(provider: SocialProviders, emailData?: IAuthData) {
        try {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            switch (provider) {
                case "google.com":
                    if (isSafari && !this.platform.is("capacitor")) {
                        await this.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
                    } else {
                        await FirebaseAuthentication.signInWithGoogle();
                    }
                    break;
                case "facebook.com":
                    if (isSafari && !this.platform.is("capacitor")) {
                        await this.auth.signInWithRedirect(new firebase.auth.FacebookAuthProvider());
                    } else {
                        await FirebaseAuthentication.signInWithFacebook();
                    }
                    break;
                case "apple.com":
                    if (isSafari && !this.platform.is("capacitor")) {
                        await this.auth.signInWithRedirect(new firebase.auth.OAuthProvider('apple.com'));
                    } else {
                        await FirebaseAuthentication.signInWithApple();
                    }
                    break;
                case "email":
                    return await this.login(emailData);
                case "register":
                    return await this.register(emailData);
                case "reset-password":
                    return await this.resetPassword(emailData);
            }

            return await this.afterLogin(isSafari);
        } catch (e) {
            return {success: false, message: e};
        }
    }

    public async afterLogin(isSafari) {
        try {
            const response = await this.getLogin(isSafari);
            if (response.success) {
                this.storage.set("token", response.data?.token);
                this.storage.set("user", response.data?.user);
                await this.checkProfile();
            }
            return {success: true, message: ""};
        } catch (e) {
            return {success: false, message: e};
        }
    }


    public async logOut() {
        try {
            this.http.get<IResponse>(this.logoutUrl).toPromise();
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isSafari && !this.platform.is("capacitor")) {
                this.auth.signOut();
            } else {
                FirebaseAuthentication.signOut();
            }
        } catch (e) {
            console.log(e);
        }
        this.clearLocalStorage();
    }

    public clearLocalStorage() {
        this.storage.remove("token");
        this.storage.remove("vehicles");
        this.storage.remove("user");
        this.storage.remove("profile");
        this.storage.remove("chats");
        this.storage.remove("favorites");
    }

    private async getLogin(isSafari: boolean = false): Promise<IResponse> {
        try {
            await this.clearLocalStorage();
            let result;
            if (isSafari) {
                result = await this.auth.idToken.pipe(take(1) as any).toPromise();
            } else {
                result = await FirebaseAuthentication.getIdToken();
            }
            return await this.http.get<IResponse>(this.loginUrl, {
                headers: {Authorization: `Bearer ${result.token || result}`}
            }).toPromise();
        } catch (e) {
            return Promise.resolve({success: false, message: e});
        }
    }

    public checkProfile() {
        const vehicles = this.storage.get("vehicles");
        const profile = this.storage.get("profile");
        const user = this.storage.get("user");

        if (!profile) {
            if (!vehicles?.length && user?.companies?.length) {
                this.setProfile(user.companies[0], "company");
            } else {
                this.setProfile(user, "user");
            }
        } else {
            switch (profile?.kind) {
                case "user":
                    this.setProfile(user, "user");
                    break;
                case "company":
                    const company = user?.companies?.find(c => c?.id === profile?.id);
                    if (company) {
                        this.setProfile(company, "company");
                    } else {
                        this.setProfile(user, "user");
                    }
                    break;
            }
        }
    }

    public setProfile(data: any, kind: "user"|"company") {
        this.storage.set("profile", {...cloneDeep(data), kind});
    }

    public async login(data: IAuthData) {
        try {
            await this.clearLocalStorage();
            const response = await this.http.post<IResponse>(this.loginUrl, data).toPromise();
            if (response?.success) {
                this.storage.set("token", response.data?.token);
                this.storage.set("user", response.data?.user);
                this.checkProfile();
            }
            return {success: true, message: ""};
        } catch (e) {
            return {success: false, message: e};
        }
    }

    public async register(data: IAuthData) {
        try {
            const response = await this.http.post<IResponse>(this.registerUrl, data).toPromise();
            if (response?.success) {
                await this.router.navigate(["/profile/login"]);
            }
            return {success: true, message: ""};
        } catch (e) {
            return {success: false, message: e};
        }
    }

    public async resetPassword(data: IAuthData) {
        try {
            const response = await this.http.post<IResponse>(this.resetPasswordUrl, data).toPromise();
            if (response?.success) {
                return {success: true, message: ""};
            }
        } catch (e) {
            return {success: false, message: e};
        }
    }

    public async findUserWithToken(resetToken: string) {
        try {
            const response = await this.http.get<IResponse>(`${environment.api}/auth/new-password/${resetToken}`).toPromise();
            if (response?.success) {
                return {success: true, message: "", user: response.data};
            }
        } catch (e) {
            return {success: false, message: e};
        }
    }

    public async newPassword(data: Partial<IUser>) {
        try {
            const response = await this.http.post<IResponse>(this.newPasswordUrl, data).toPromise();
            if (response?.success) {
                return {success: true, message: ""};
            }
        } catch (e) {
            return {success: false, message: e};
        }
    }

    public connectPartner(token: string): Promise<IResponse> {
        try {
            return this.http.get<IResponse>(this.connectUrl + "/" + token).toPromise();
        } catch (e) {
            return Promise.resolve({success: false, message: e});
        }
    }

    public approvePartnerConnect(token: string, data: any): Promise<IResponse> {
        try {
            return this.http.put<IResponse>(this.connectUrl + "/" + token, data).toPromise();
        } catch (e) {
            return Promise.resolve({success: false, message: e});
        }
    }

}
