export const environment = {
    production: true,
    name: "web",
    appId: "com.garagebit.app",
    sw: true,
    languages: ["en", "el", "de"],
    defaultLang: "el",
    homepage: "vehicle-search",
    banner: {
        enabled: true
    },
    apiRoot: "https://api.garagebit.com",
    api: "https://api.garagebit.com/app",
    liveUrl: "https://garagebit.com",
    search: {
        vehicleType: 1, // cars
        limit: 12
    },
    images: {
        company: "/assets/thumb.jpg",
        user: "/assets/images/general/driver.png"
    },
    maps: {
        apiKey: "AIzaSyB_QmdEsskyT1gu2BAkcY6CIETb93GFG3w",
        center: {
            lat: 40.6401,
            lng: 22.9444
        }
    },
    firebase: {
        apiKey: "AIzaSyB20lq6fuyuy435rNunmoOA3YU-kQgsisM",
        authDomain: "garagebit-app.firebaseapp.com",
        databaseURL: "https://garagebit-app.firebaseio.com",
        projectId: "garagebit-app",
        storageBucket: "garagebit-app.appspot.com",
        messagingSenderId: "269584576328",
        appId: "1:269584576328:web:4445db149f77aa06aa4d7d",
        measurementId: "G-D3P83ZL660"
    },
    pusher: {
        key: "c8f1889bed08c7b265c6",
        cluster: "eu",
    },
    analytics: {
        googleTrackingId: "G-YTB21C0BD1",
        facebookPixelId: "1603678813137536",
        microsoftClarityEnabled: true,
        microsoftClarityProjectId: "gkc1nugw8r"
    },
    rollbar: {
        environment: "production",
        accessToken: "769466d1e2af4c52b7a676a6f03aa2f0",
        enabled: true
    },
    payments: {
        stripe: {
            key: "pk_live_51KCj3TLqk0uXWAGAgDQ5kWZeZgMOezKMlFrOGEc40aX660j4r7ni8XAJVXDOaCMtvdPcv88CTpaQld6iJwmu4evA00qQLIDyqR"
        }
    }
};
