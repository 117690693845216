import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {mergeMap, mergeMapTo} from "rxjs/operators";
import {Platform} from "@ionic/angular";
import {PushNotifications, ActionPerformed, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {Subject} from "rxjs";
import {INotification, INotificationAction} from "../interfaces/INotification";
import {ApiService} from "./api.service";
import {IUser} from "../interfaces/IUser";
import {SuperStorage} from "../decorators/storage";

@Injectable({
    providedIn: "root"
})
export class MessagingService {

    public notification$: Subject<INotification> = new Subject<INotification>();
    public notificationAction$: Subject<INotificationAction> = new Subject<INotificationAction>();

    @SuperStorage("device-token")
    public deviceToken: string;

    @SuperStorage("user")
    public user: IUser;

    constructor(
        private platform: Platform,
        private messaging: AngularFireMessaging,
        private api: ApiService
    ) {}

    public initializePushNotifications() {
        if (this.platform.is("capacitor")) {
            // @ts-ignore
            PushNotifications.requestPermissions().then((result) => {
                switch (result.receive) {
                    case "granted":
                        PushNotifications.register();
                        break;
                    case "denied":
                    case "prompt":
                    case "prompt-with-rationale":
                        this.onError(result);
                        break;
                }
            });
            PushNotifications.addListener('registration', (token: Token) => {
                this.onSuccess(token.value);
            });
            PushNotifications.addListener('registrationError', this.onError.bind(this));
            PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
                this.notification$.next(notification);
            });
            PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
                this.notificationAction$.next(action);
            });
        } else {
            try {
                this.messaging.requestPermission
                    // @ts-ignore
                    .pipe(mergeMapTo(this.messaging.tokenChanges))
                    // @ts-ignore
                    .subscribe((token) => this.onSuccess(token), this.onError.bind(this));

                this.messaging.messages.subscribe((payload: any) => {
                    this.notification$.next(payload.notification);
                });
            } catch (e) {
                console.warn(e);
            }
        }
    }

    public onSuccess(token: string) {
        if (this.user?.id) {
            this.api.updateDeviceToken(token, this.deviceToken).subscribe();
        }
        this.deviceToken = token;
    }

    public onError(error: any) {
        console.error(error);
    }

    public deleteToken() {
        if (this.platform.is("capacitor")) {
            // Delete token for Capacitor
        } else {
            this.messaging.getToken
                // @ts-ignore
                .pipe(mergeMap(token => this.messaging.deleteToken(token)))
                .subscribe((token) => console.log('Token deleted!'));
        }
    }

    public subscribeToTopic(topicName: string) {
        if (this.deviceToken) {
            this.api.subscribeDeviceTokensToTopic([this.deviceToken], topicName).subscribe();
        }
    }
}
