import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ApiService} from "../../../services/api.service";


@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.html',
    styleUrls: ['./login-modal.scss'],
})
export class LoginModal implements OnInit {

    constructor(
        public modalCtrl: ModalController,
        public api: ApiService
    ) {
    }

    ngOnInit() {
    }

    public async loggedIn() {
        await this.modalCtrl.dismiss(null, "success");
    }

}
